import React from 'react';
import './M7.css'; // Ensure the styles are in place

// Import images (assuming the images are stored locally in the 'landimg' folder)
import img1 from '../landimg/1.jpg';
import img2 from '../landimg/2.jpg';
import img3 from '../landimg/3.jpg';
import img4 from '../landimg/4.jpg';
import img5 from '../landimg/5.jpg';
import img6 from '../landimg/6.jpg';

const M7 = () => {
  // Description array corresponding to each image
  const descriptions = [
    "apply the gel to the probe tip and pull the protective cover over the probe.",
    "Install the guide by aligning the fixing point with the guide.",
    "secure the guide by inserting the locking arm into the locker slot.",
    "Apply gel to the tip of the lid and insert the probe.",
    "The blue release/locking button sets the throat plate horizontally.",
    "turn the probe to the desired area. Insert the instrument through a channel that corresponds to the target.",
  ];

  // Array of imported images
  const images = [img1, img2, img3, img4, img5, img6];

  return (
    <>
    <h1 className="m-header" >Intutive Setup</h1>
    <div className="m7-container">
      {/* Loop through the images and descriptions */}
      {descriptions.map((description, index) => (
        <div key={index} className="m7-step">
          <div className="m7-item">
            <img src={images[index]} alt={`Image ${index + 1}`} className="m7-image" />
            <p className="m7-description" style={{textAlign :'justify' , color:'grey',fontSize:'16px' }}>{description}</p> {/* Wrapped in <p> tag */}
          </div>
          {/* Display arrow between items */}
          {index < descriptions.length - 1 && index % 3 !== 2 ? (
            <div className="m7-arrow">&#8594;</div> // Right arrow
          ) : index % 3 === 2 ? (
            <div className="m7-arrow-u-turn"></div> // U-turn arrow after 3rd image
          ) : null}
        </div>
      ))}
    </div>

    </>
  );
};

export default M7;
