import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import imgStepperGrid from '../images/Stepper-grid_guided-removebg-preview (1).png';
import imgFreeHand from '../images/second.png';
import './Testimonial.css';

const Testimonial = () => {
  return (
    <div className="testimonial-container">
      <div className="testimonial-left">
      <h2 style={{fontSize:'3em',color:'#035cee'}}>Service</h2>
        <p>
          Our team handles everything from booking to delivery setup, and operation. We supply all equipment and consumables, ensuring a smooth, efficient process.
        </p>
        <button className="testimonial-btn">
          <Link to="/ProBook">Read More</Link>
        </button>
      </div>

      <div className="testimonial-right">
        {/* Image 1 with Overlay */}
        <div className="testimonial-image-container">
          <img src={imgStepperGrid} alt="Stepper Grid" className="testimonial-image" />
          <div className="overlay">
            <div className="overlay-text">Cognitive Stepper-grid guided transperineal biopsy</div>
          </div>
        </div>

        {/* Image 2 with Overlay */}
        <div className="testimonial-image-container">
          <img src={imgFreeHand} alt="Free Hand" className="testimonial-image" />
          <div className="overlay">
            <div className="overlay-text">Cognitive Free hand biopsy</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
