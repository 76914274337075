import React from 'react';
import './Three.css'; // Updated CSS file name

function Three() {
  return (
    <div className="three">
      {/* Content can be added here */}
    </div>
  );
}

export default Three;
