import React from 'react';
import './Phasetwo.css';

function Phasetwo() {
  return (
    <div className="phase-two-container">
     

    </div>
  );
}

export default Phasetwo;
