import React from 'react';
import './lan.css'; // Updated CSS file name

function Lan() {
  return (
    <div className="partthree">
      {/* Content can be added here */}
    </div>
  );
}

export default Lan;
