import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../images/logo1.png'; // Original logo

function Navbar_About() {
  const [isMobile, setIsMobile] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
    setDropdown(false); // Close the dropdown when opening the mobile menu
  };

  // Toggle dropdown menu visibility
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent the event from propagating up to the mobile menu
    setDropdown(!dropdown);
  };

  // Close dropdown if clicked outside
  const closeDropdown = () => {
    setDropdown(false);
  };

  // Close the mobile menu and dropdown if clicked outside
  const handleClickOutside = (e) => {
    if (isMobile && !e.target.closest('.NavbarOne')) {
      setIsMobile(false); // Close mobile menu if clicked outside
      setDropdown(false); // Close dropdown if clicked outside
    } else if (dropdown && !e.target.closest('.NavbarOne-dropdown')) {
      setDropdown(false); // Close dropdown if clicked outside dropdown
    }
  };

  // Attach event listener to detect clicks outside
  useEffect(() => {
    if (isMobile || dropdown) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile, dropdown]);

  return (
    <nav className="NavbarOne">
      <div className="NavbarOne-left">
        <Link to="/">
          {/* Always use logo1 as the default logo */}
          <img src={logo1} alt="Logo" className="NavbarOne-logo" />
        </Link>

        {/* Navbar links */}
        <ul className={isMobile ? 'NavbarOne-nav-links mobile active' : 'NavbarOne-nav-links'}>
        <li><Link to="/">Home</Link></li>
          <li><Link to="/ForPatients">For Patients</Link></li>
          <li className="NavbarOne-dropdown">
            <a href="#!" onClick={toggleDropdown}>our Product</a>
            {dropdown && (
              <div className="NavbarOne-dropdown-menu">
                <Link to="/Sales">Sales</Link>
                <Link to="/ProBook">Services</Link>
              </div>
            )}
          </li>
          <li><Link to="/ForProviders">Biopsy Box</Link></li>
          <li><Link to="/Tower">Tower</Link></li>

          {/* Add Contact and Buy Now links inside the mobile menu */}
          {isMobile && (
            <>
              <li style={{ marginTop: '20px' }}><Link to="/contact">Contact</Link></li>
              <li><Link to="/buy-now" className="buy-now-button">Buy Now</Link></li>
            </>
          )}
        </ul>
      </div>

      {/* Right end items: Contact & Buy Now (Desktop only) */}
      {!isMobile && (
        <div className="NavbarOne-right">
          <ul>
            <li style={{ marginTop: '20px' }}><Link to="/contact">Contact</Link></li>
            <li><Link to="/buy-now" className="buy-now-button">Buy Now</Link></li>
          </ul>
        </div>
      )}

      {/* Mobile menu icon */}
      <div className="NavbarOne-menu-icon" onClick={toggleMobileMenu}>
        <i style={{ marginRight: '20px', color: 'black' }} className={isMobile ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
    </nav>
  );
}

export default Navbar_About;
