import React from 'react';
import './Info.css';

export default function Info() {
  return (
    <>
      <h1 style={{ color: '#003366', textAlign: 'center', fontSize: '3rem' }}>Comprehensive Package Inclusions</h1>
      <div className="package-container">
        <div className="package-box">
          <h2 className="package-heading">Ultrasound Services</h2>
          <p className="package-description">
            Advanced ultrasound services are provided as part of our package to ensure precise imaging and accurate diagnosis.
          </p>
        </div>
        <div className="package-box">
          <h2 className="package-heading">System Specialist Support</h2>
          <p className="package-description">
            Our expert system specialists work alongside your team to facilitate seamless operations and ensure each patient receives a quality experience.
          </p>
        </div>
        <div className="package-box">
          <h2 className="package-heading">Consumables – Completely Free</h2>
          <p className="package-description">
            We supply all necessary consumables, including our advanced Surefire Needle Guide, at no additional cost, making our package both convenient and cost-efficient.
          </p>
        </div>
      </div>
    </>
  );
}
