import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../images/logo1.png'; // Default logo

function Phaseone() {
  const [navbar, setNavbar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  // Change navbar background color when scrolling
  const changeBackground = () => {
    setNavbar(window.scrollY >= 80); // Simplified condition
  };

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
    setDropdown(false); // Close dropdown when mobile menu is opened
  };

  // Toggle dropdown visibility
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent event propagation to mobile menu
    setDropdown(!dropdown);
  };

  // Close dropdown if clicked outside
  const closeDropdown = () => {
    setDropdown(false);
  };

  // Close mobile menu and dropdown if clicked outside
  const handleClickOutside = (e) => {
    if (isMobile && !e.target.closest('.NavbarOne')) {
      setIsMobile(false); // Close mobile menu if clicked outside
      setDropdown(false); // Close dropdown if clicked outside
    } else if (dropdown && !e.target.closest('.NavbarOne-dropdown')) {
      setDropdown(false); // Close dropdown if clicked outside dropdown
    }
  };

  useEffect(() => {
    // Add scroll event listener on component mount
    window.addEventListener('scroll', changeBackground);

    // Add event listener to detect clicks outside
    if (isMobile || dropdown) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', changeBackground);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile, dropdown]);

  return (
    <nav className={navbar ? 'NavbarOne active' : 'NavbarOne'}>
      <div className="NavbarOne-left">
        {/* Logo with scroll effect */}
        <Link to="/">
          <img src={logo1} alt="Logo" className="NavbarOne-logo" />
        </Link>

        {/* Navbar links */}
        <ul className={isMobile ? 'NavbarOne-nav-links mobile active' : 'NavbarOne-nav-links'}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li className="NavbarOne-dropdown">
            <a href="#!" onClick={toggleDropdown}>our Product</a>
            {dropdown && (
              <div className="NavbarOne-dropdown-menu">
                <Link to="/Sales">Sales</Link>
                <Link to="/ProBook">Services</Link>
              </div>
            )}
          </li>
          <li><Link to="/ForProviders">Biopsy Box</Link></li>
          <li><Link to="/Tower">Tower</Link></li>

          {/* Mobile-specific links */}
          {isMobile && (
            <>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/buy-now" className="buy-now-button">Buy Now</Link></li>
            </>
          )}
        </ul>
      </div>

      {/* Right end items: Contact & Buy Now (Desktop only) */}
      {!isMobile && (
        <div className="NavbarOne-right">
          <ul>
            <li  style={{ marginTop: '20px' }}><Link to="/contact">Contact</Link></li>
            <li><Link to="/buy-now" className="buy-now-button">Buy Now</Link></li>
          </ul>
        </div>
      )}

      {/* Mobile Menu Icon */}
      <div className="NavbarOne-menu-icon" onClick={toggleMobileMenu} style={{color:'black'}}>
        <i className={isMobile ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
    </nav>
  );
}

export default Phaseone;
