import React from 'react';
import './About.css';
import logo1 from '../images/GPS 1.png'; // Import the image

function About() {
  return (
    <div className="about-container">
      <div className="about-content">
        {/* Left Column - Image */}
        <div className="about-image">
          <img src={logo1} alt="About Us" />
        </div>

        {/* Right Column - Description */}
        <div className="about-description">
          <h2 style={{fontSize:'2.5rem',color:'#035cee'}}>Precision in Every Shot:<br></br> Revolutionise Your Biopsy</h2>
          <p>
          Global Prostate Solution, established in 2012, is a leading innovator based in the UK, specialising in the development and provision of advanced prostate biopsy solutions. Over the years, we have built a reputation for excellence, driven by our commitment to delivering high-quality diagnostic services. Our dedicated team operates around the clock to ensure that our clients receive unparalleled customer service and support.
           </p>
           {/* <span  >Read More</span> */}
        </div>
      </div>
    </div>
  );
}

export default About;
