import React, { useState } from 'react';
import './Dashboard.css';  // Import the custom CSS

const Dashboard = () => {
  // State to track which set of boxes are visible
  const [currentSet, setCurrentSet] = useState(1);

  // Function to toggle between box sets
  const toggleBoxes = (direction) => {
    if (direction === 'right') {
      // Move to the next set (from 1 to 2 or from 2 to 1)
      setCurrentSet(currentSet === 1 ? 2 : 1);
    } else if (direction === 'left') {
      // Move to the previous set (from 2 to 1 or from 1 to 2)
      setCurrentSet(currentSet === 2 ? 1 : 2);
    }
  };

  return (
    <div className="dashboard-container">
      <h1 className="title">Loved & <br></br> Trusted</h1>

      <div className="box-wrapper">
        {/* Testimonial 1 */}
        {currentSet === 1 && (
          <div className="box-item visible">
            <div className="testimonial-text">
              <p>"Global Prostate Solution has been an invaluable partner in advancing prostate cancer diagnostics. Their innovative ultrasound technology has significantly enhanced the accuracy of our biopsy procedures, enabling us to detect cancers earlier and more precisely. The level of patient care and support they provide is exceptional, ensuring that each patient receives personalized attention throughout the process. I highly recommend their services to any healthcare provider looking to improve prostate cancer detection and patient care."</p>
            </div>
            <div className="testimonial-author">
              <img
                src="https://i.pravatar.cc/100?img=8"
                alt="John Doe"
                className="testimonial-photo"
              />
              <div className="author-name">
                <strong>Dr. James Hargrave, MD</strong>
                <div className="author-title">
                Consultant Urologist
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Testimonial 2 */}
        {currentSet === 1 && (
          <div className="box-item visible">
            <div className="testimonial-text">
              <p>"As a nurse working in the oncology unit, I've seen first-hand the importance of accurate and timely diagnostics in cancer care. The team at Global Prostate Solution has set a high standard in providing advanced biopsy solutions. Their patient-centered approach, combined with their state-of-the-art technology, provides peace of mind to both patients and healthcare providers. Their support is continuous, and they truly care about improving patient outcomes"</p>
            </div>
            <div className="testimonial-author">
              <img
                src="https://i.pravatar.cc/100?img=16"
                alt="Jane Smith"
                className="testimonial-photo"
              />
              <div className="author-name">
                <strong>Sarah Mitchell, RN</strong>
                <div className="author-title">
                Head Nurse
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Testimonial 3 */}
        {currentSet === 2 && (
          <div className="box-item visible">
            <div className="testimonial-text">
              <p>"In my research, I’ve had the opportunity to collaborate with Global Prostate Solution, and I’m continually impressed with their commitment to innovation and excellence. Their advanced biopsy solutions are among the best in the field, ensuring that healthcare providers have the tools they need for accurate prostate cancer diagnosis. The team is dedicated to improving clinical outcomes, and their focus on continuous education and training keeps them at the forefront of this critical area of healthcare"</p>
            </div>
            <div className="testimonial-author">
              <img
                src="https://i.pravatar.cc/100?img=12"
                alt="Robert Brown"
                className="testimonial-photo"
              />
              <div className="author-name">
                <strong>Dr. William Shaw, PhD</strong>
                <div className="author-title">
                Clinical Researcher in Urology
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Testimonial 4 */}
        {currentSet === 2 && (
          <div className="box-item visible">
            <div className="testimonial-text">
              <p>"I’ve referred several patients to Global Prostate Solution for prostate biopsies, and I’ve always been impressed with the level of service and professionalism they provide. They not only offer cutting-edge technology but also prioritize patient comfort and well-being throughout the entire process. The flexible rental options are particularly useful for practices like mine, and the support team is always available to answer questions and provide assistance. They make the complex process of prostate cancer diagnosis much more accessible and manageable for both patients and healthcare providers."</p>
            </div>
            <div className="testimonial-author">
              <img
                src="https://i.pravatar.cc/100?img=23"
                alt="Emily White"
                className="testimonial-photo"
              />
              <div className="author-name">
                <strong> Dr. Emily Tran, MBBS</strong>
                <div className="author-title">
                General Practitioner
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Arrow Buttons */}
      <div className="toggle-buttons">
        <span className="arrow-button" onClick={() => toggleBoxes('left')}>&#8592;</span> {/* Left Arrow */}
        <span className="arrow-button" onClick={() => toggleBoxes('right')}>&#8594;</span> {/* Right Arrow */}
      </div>
    </div>
  );
};

export default Dashboard;
