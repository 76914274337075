// L2.js
import React from 'react';
import './L2.css'; // Updated the CSS file import

function L2() {
  return (
    <div className="l2-container"> {/* Updated the class name */}
      {/* Add content here if needed */}
    </div>
  );
}

export default L2;
