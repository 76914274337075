import './M5.css';
import React from 'react';

import image1 from '../NEWIMG/red.jpg';

export default function M5() {
    return (
        <div className="m5-container">
            <div className="m5-description">
                <h1 className="m9-header">Compatible with:</h1>
                <ul
                    className="description-list"
                    style={{ color: '#555', marginTop: '50px', columnCount: 2, columnGap: '20px' }}
                >
                    <li className="description-item">We use the </li>
                    <li className="description-item">Our tools aid</li>
                    <li className="description-item">We uphold high </li>
                    <li className="description-item">We use the </li>
                    <li className="description-item">Our tools aid</li>
                    <li className="description-item">We uphold high </li>
                    <li className="description-item">We use the </li>
                    <li className="description-item">Our tools aid</li>
                </ul>

            </div>
            <div className="m5-images">
                <img src={image1} alt="Prostate Health Image 1" className="m5-image" />
            </div>
        </div>
    );
}
