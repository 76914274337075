import React from 'react';
import './Six.css'; // Updated CSS file name

function Six() {
  return (
    <div className="Six">
      {/* Content can be added here */}
    </div>
  );
}

export default Six;
