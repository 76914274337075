import React from 'react';
import './Five.css'; // Updated CSS file name

function Five() {
  return (
    <div className="Five">
      {/* Content can be added here */}
    </div>
  );
}

export default Five;
