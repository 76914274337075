import React from 'react';
import './Partthree.css'; // Updated CSS file name

function Partthree() {
  return (
    <div className="partthree">
      {/* Content can be added here */}
    </div>
  );
}

export default Partthree;
