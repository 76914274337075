import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../images/logo1.png'; // Default logo

function Mone() {
  const [isMobile, setIsMobile] = useState(false); // Track mobile menu state
  const [dropdown, setDropdown] = useState(false); // Track dropdown menu state

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  // Toggle dropdown menu visibility
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <nav className="NavbarOne">
      <div className="NavbarOne-left">
        <Link to="/">
          <img src={logo1} alt="Logo" className="NavbarOne-logo" />
        </Link>

        {/* Navbar links */}
        <ul className={isMobile ? 'NavbarOne-nav-links mobile active' : 'NavbarOne-nav-links'}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/ForPatients">For Patients</Link></li>

          {/* Dropdown Menu for "For Product" */}
          <li className="NavbarOne-dropdown">
            <a href="#!" onClick={toggleDropdown}>our Product</a>
            {dropdown && (
              <div className="NavbarOne-dropdown-menu">
                <Link to="/Sales">Sales</Link>
                <Link to="/ProBook">Services</Link>
              </div>
            )}
          </li>

          <li><Link to="/Tower">Tower</Link></li>

          {/* Mobile links (Contact & Buy Now) */}
          {isMobile && (
            <>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/buy-now" className="buy-now-button">Buy Now</Link></li>
            </>
          )}
        </ul>
      </div>

      {/* Right end items: Contact & Buy Now (only for desktop) */}
      {!isMobile && (
        <div className="NavbarOne-right">
          <ul>
            <li style={{marginTop:'20px'}}><Link to="/contact">Contact</Link></li>
            <li><Link to="/buy-now" className="buy-now-button">Buy Now</Link></li>
          </ul>
        </div>
      )}

      {/* Mobile menu icon */}
      <div className="NavbarOne-menu-icon" onClick={toggleMobileMenu} style={{color:'black'}}>
        <i className={isMobile ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
    </nav>
  );
}

export default Mone;
