import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/gps-logo-white.025ee053.png';
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        
        {/* Left Section */}
        <div className="footer-left">
          <img src={logo} alt="Global Prostate Solutions" className="footer-logo" />
          <p className="tagline">Precision. Innovation. Reliability.</p>
          <div className="social-links">
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebookF />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              <FaYoutube />
            </a>
          </div>
          <div className="footer-legal-links">
            <Link to="/privacy" className="footer-link">Privacy Policy</Link>
            <Link to="/terms" className="footer-link">Terms of Service</Link>
          </div>
          <p className="footer-description">
            Global Prostate Solutions, established in 2012, is an innovative UK-based company specializing in comprehensive prostate biopsy solutions. With an experienced 24/7 team and a commitment to excellent customer service, we provide cutting-edge diagnostic services for accurate and timely prostate cancer detection.
          </p>
        </div>

        {/* Middle Section */}
        <div className="footer-middle">
          <h3 className="footer-title">Links</h3>
          <ul className="footer-links-list">
            <li><span className="link-icon">—</span><Link to="/about-us" className="footer-link">About Us</Link></li>
            <li><span className="link-icon">—</span><Link to="/products" className="footer-link">Our Products</Link></li>
            <li><span className="link-icon">—</span><Link to="/patients" className="footer-link">For Patients</Link></li>
            <li><span className="link-icon">—</span><Link to="/biopsy-box" className="footer-link">TP Biopsy Box</Link></li>
            <li><span className="link-icon">—</span><Link to="/global-innovations" className="footer-link">Global Innovations</Link></li>
          </ul>
        </div>

        {/* Right Section - Newsletter Signup */}
        <div className="footer-right">
          <div className="newsletter-section">
            <h3 className="newsletter-title">Sign up to our newsletter</h3>
            <p className="newsletter-text">Receive the latest news, exclusive discounts & offers straight to your inbox!</p>
            <form className="newsletter-form">
              <input type="email" placeholder="Email address" className="newsletter-input" />
              <button type="submit" className="newsletter-submit">Submit</button>
            </form>
          </div>

          {/* Contact Us Section outside of newsletter box */}
          <div className="contact-info">
            <p><strong>Contact Us</strong></p>
            <p>+44 (0) 124 539 2110</p>
            <p>query.globalprostatesolution@gmail.com</p>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="footer-bottom">
        <p>© 2024 Global Prostate Solutions</p>
        <p>Designed & developed by <a href="https://mark418.com" target="_blank" rel="noopener noreferrer">Mark4Bi</a></p>
      </div>
    </footer>
  );
};

export default Footer;
