import React from 'react'

export default function InfoTwo() {
  return (
    <div>
           <div className="bottom-section-phasethree">
        <h2 className="bottom-header-phasethree">Why Choose Global Prostate Solutions?</h2>
        <p className="bottom-paragraph-phasethree " style={{width:'95%'}}>
        With our specialised focus on reducing patient backlogs, Global Prostate Solutions is committed to helping hospitals improve patient flow and satisfaction. Our team brings a wealth of expertise and cutting-edge resources to make sure your patients are served with accuracy, speed, and professionalism

        Optimise your hospital’s operations, enhance patient care, and clear your backlog efficiently with our tailored services. Get in touch with us to discuss how Global Prostate Solutions can become your trusted partner in patient management.
        </p>
       
      </div>
      
    </div>
  )
}
