import React from 'react';
import './M2.css';


function LandingPage() {
  return (
    <div className="M2_landing-page">
      
    </div>
  );
}

export default LandingPage;
