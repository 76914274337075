import React from 'react';
import './Land_About.css';


function Land_About() {
  return (
    <div className="land-about">
 
    </div>
  );
}

export default Land_About;
