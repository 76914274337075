import './M4.css';
import React from 'react';
import image1 from '../NEWIMG/insert.jpg';

export default function M4() {
  return (
    <div className="mfour-container">
      <div className="mfour-description "  style={{ marginTop:'70px'}}>
      <h1 className="m9-header">Why SureFire is Universal?</h1>
        <ul className="description-list" style={{color:'#555' , marginTop:'50px'}}>
            <li className="description-item">We use the latest tools for precise prostate cancer detection.</li>
            <li className="description-item"> Our tools aid in accurate diagnosis and patient care</li>
            <li className="description-item">We uphold high service and reliability standards.</li>

          </ul>
      </div>
      <div className="mfour-images">
        <img src={image1} alt="Prostate Health Image 1" className="mfour-image" />
      </div>
    </div>
  );
}
