import React, { useState, useEffect } from 'react';
import './M6.css'; // Updated CSS file for M6 styling
import desktopImage from '../NEWIMG/surefireneedle.jpg'; // Import the desktop image
import mobileImage from '../NEWIMG/surefireneedle.jpg'; // Import the mobile image

const M6 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Set initial state based on window size

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update state on window resize
    };

    window.addEventListener('resize', handleResize); // Attach resize event listener
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up listener on unmount
    };
  }, []);

  return (
    <>
       <h1 className="m-header" >Interact with the dots for more details</h1>
    <div className="m6-container">
      
      <div className="m6-image-container">
        <img
          src={isMobile ? mobileImage : desktopImage} // Use mobile or desktop image based on state
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/300'; // Fallback if image fails to load
          }}
          alt="Descriptive Alt Text"
          className="m6-image"
        />

        {/* Dot Label 1 */}
        <div className="m6-dot" style={{ top: '27%', left: '24%', zIndex: 100 }}>
  <div className="m6-dot-orbit" style={{ zIndex: 0 }}></div>
  <div className="msg" style={{ zIndex: 1000 }}>Clear Vertical Indicator With 9 Settings</div>
</div>

{/* Dot Label 2 */}
<div className="m6-dot" style={{ top: '33%', left: '45%', zIndex: 10 }}>
  <div className="m6-dot-orbit"  style={{ zIndex: 0 }}></div>
  <div className="msg" style={{ zIndex: 1000 }}>Flexible Length Adjustment</div>
</div>

{/* Dot Label 3 */}
<div className="m6-dot" style={{ top: '24%', left: '57%', zIndex: 1 }}>
  <div className="m6-dot-orbit"  style={{ zIndex: 0 }}></div>
  <div className="msg" style={{ zIndex: 1000 }}>Locker Button Secures The Channel Guide</div>
</div>

{/* Dot Label 4 */}
<div className="m6-dot" style={{ top: '54%', left: '63%', zIndex: 0 }}>
  <div className="m6-dot-orbit"  style={{ zIndex: 0 }}></div>
  <div className="msg" style={{ zIndex: 1000 }}>Rapid Setup</div>
</div>

      </div>
    </div>
    </>
  );
};

export default M6;
